import React from 'react'
import Link from 'gatsby-link'
import Logo from './Logo/'

import facebook from '../images/icons/if_facebook.png'
import instagram from '../images/icons/if_instagram.png'
import twitter from '../images/icons/if_twitter.png'
import linkedIn from '../images/icons/if_linkedIn.png'
import logoStacked from '../images/logos/logo-stacked.png'

const Footer = ({data}) => (
  <div className="tc center pt4 pb5 bg-frendli-black white">
    <div className="flex flex-wrap-reverse flex-wrap-ns justify-center items-start pa2">
      <div className="w-100 w-25-ns">
        <img alt="Frendli Logo" className="w-40-ns pb3 center" src={logoStacked}/>
        <div className="w-40-ns flex flex-wrap justify-center center items-center">
        	<span className="w-50-ns pb2"><a href={data.site.siteMetadata.facebook} rel="noopener noreferrer" target="_blank" className="pa2"><img alt="Share on Facebook" className="w-50 w-50-ns" src={facebook}/></a></span>
        	<span className="w-50-ns pb2"><a href={data.site.siteMetadata.instagram} rel="noopener noreferrer" target="_blank" className="pa2"> <img alt="Share on Instagram" className="w-50  w-50-ns" src={instagram}/></a></span>
	        <span className="w-50-ns pb2"><a href={data.site.siteMetadata.twitter} rel="noopener noreferrer" target="_blank" className="pa2"><img alt="Share on Twitter" className="w-50 w-50-ns" src={twitter}/></a></span>
	        <span className="w-50-ns pb2"><a href={data.site.siteMetadata.linkedIn} rel="noopener noreferrer" target="_blank" className="pa2"><img alt="Share on LinkedIn" className="w-50 w-50-ns" src={linkedIn}/></a></span>
	    </div>
        <p className="f7 lh-copy fw1 w-100 tc ">&copy; Copyright Frendli Inc</p>
      </div>
  
      <div className="w-100 w-50-ns pt0">
        <h3 className="lh-headline lh-copy  tc tl-ns fw3 pt0 mt0 f5 f3-ns mh2 mh0-ns ml0 pl0">Frendli is live everywhere and Frendli Deals are live in San Diego!</h3>
        <p className="lh-copy f6 f6-ns fw1 tc tl-ns ">
          Frendli is the only service of its kind pairing people who have a lot in common with awesome deals to share.
          We're here to help everyone build a great circle of friends.
        </p>
        <div className="flex w-100 center w-100-ns flex-wrap tc tl-ns justify-left items-start pb5 pt2">
        	<span className="w-third w-50-m w-25-l ml0 pb2 f6 f7-ns fw1"><a href={data.site.siteMetadata.blog} className="pa2 pl0 frendli-blue">Blog</a></span>
        	<span className="w-third w-50-m w-25-l pb2 f6 f7-ns fw1"><a href={data.site.siteMetadata.merchants} className="pa2 pl0 frendli-blue">Merchants</a></span>
        	<span className="w-third w-50-m w-25-l pb2 f6 f7-ns fw1"><a href={data.site.siteMetadata.download} className="pa2 pl0 frendli-blue">Get Frendli</a></span>
        	<span className="w-third w-50-m w-25-l pb2 f6 f7-ns fw1"><a href={data.site.siteMetadata.support} className="pa2 pl0 frendli-blue">Support</a></span>
        	<span className="w-third w-50-m w-25-l pb2 f6 f7-ns fw1"><a href={data.site.siteMetadata.terms} className="pa2 pl0 frendli-blue">Terms of Use</a></span>
        	<span className="w-third w-50-m w-25-l pb2 f6 f7-ns fw1"><a href={data.site.siteMetadata.privacy} className="pa2 pl0 frendli-blue">Privacy Policy</a></span>
        	<span className="w-100 w-50-l pb2 f6 f7-ns fw1"><a href={data.site.siteMetadata.caTerms} className="pa2 pl0 frendli-blue">Your California Privacy Rights</a></span>
        </div>
      </div>
    </div>
  </div>
)

export default Footer