import React from 'react'
import Link from 'gatsby-link'
import LogoImg from './logo.png'
import css from './logo.css'

const Logo = ({ someProp }) => (
    <a href="/" className="w-50 w-30-m w-20-l tl pl2 pl1-ns" title="Frendli.com">
    	<div className="fl pl6 w-100 tl logo db"></div>
 	</a>
)

export default Logo
