import React from 'react'
import Link from 'gatsby-link'

import Download from './Download/'
import Logo from './Logo/'
import Menu from './Menu/'

const Header = ({ data }) => (
  <header
    className="tc center mt0 mb0 w-100 mw8"
  >
    <div className="flex justify-center items-center center w-100 w-85-m w-75-l">
      
        <Logo/>
        <Menu/>
        
        {/* SITE NAV */}
        <nav className="w-0 w-20-m w-60-l fl nowrap tl ma0 pa0 dn flex-ns justify-center items-center justify-around ">

          
         {/* <Link
            to="../how-it-works/"
            className="outline pa2"
          >
            How it works
          </Link>*/}
          
          {/*<Link
            to="../about/"
            className="pa2 f8"
          >
            ABOUT
          </Link>

          <Link
            to="../press/"
            className="pa2 f8"
          >
            PRESS
          </Link>*/}
        <a
          href={data.site.siteMetadata.merchants}
          className="padot5 f8 pl4-m"
        >
          BECOME A MERCHANT
        </a>
        
        <a
          href={data.site.siteMetadata.blog}
          className="padot5 f8 pr3-m"
        >
          BLOG
        </a>
       
      </nav>
      {/* DOWNLOAD BUTTON HEADER*/}
      { /* @todo: fix logo css to remove btnb isDownloadPage && <Download /> */ }
      <Download />
    </div>
  </header>
)

export default Header
