import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {  StaticQuery, graphql } from "gatsby"
import Header from '../components/header'
import Footer from '../components/footer'
import '../css/main.css'
import '../css/tachyons.css'

import icon from '../images/icons/icon.png'
import favicon from '../images/icons/favicon.ico'
import squareLogo from '../images/icons/frendli_logo_square_300x300.jpg'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          blog,
          caTerms,
          download,
          facebook,
          google,
          instagram,
          linkedIn,
          merchants,
          privacy,
          siteUrl,
          support,
          terms,
          title,
          twitter
        }
      }
    }
  `}
    render={data => (
      <div className="m0 p0">

      <Helmet
        title={data.site.siteMetadata.title}
        meta={[
          { name: 'title', content: data.site.siteMetadata.title},

        ]}
      >
        <html lang="en" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5"/>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
        <meta name="title" content="Frendli - Life's Better With Friends"/>
        <meta name="description" content="Frendli is creating an easier way to meet great friends and share new experiences."/>
        <meta name="robots" content="index, follow"/>
        <meta property="fb:app_id" content="156172145105634"/>
        <meta property="og:description" content="Frendli is creating an easier way to meet great friends and share new experiences."/>
        <meta property="og:image" content="squareLogo"/>
        <meta property="og:site_name" content="Frendli.com"/>
        <meta property="og:title" content="Frendli - Life's Better With Friends"/>
        <meta property="og:type" content="article"/>
        <meta property="og:url" content="https://www.frendli.com/"/>
        <meta name="twitter:card" content="summary"/> <meta name="twitter:site" content="@getfrendli"/>
        <meta name="twitter:creator" content="@getfrendli"/>
        <meta name="twitter:title" content="Frendli - Life's Better With Friends"/>
        <meta name="twitter:description" content="Frendli is creating an easier way to meet great friends and share new experiences."/>
        <link rel="canonical" href={data.site.siteMetadata.siteUrl}/>
        <link rel="icon" href={favicon} type="image/x-icon"/>
        <link rel="apple-touch-icon" href={icon}/>
      </Helmet>
      <Header data={data} />
      <div
        className="w-100 center"
        data={data}>
        {children}
      </div>
      <Footer data={data}/>
    </div>
    )}

  />
)

export default Layout


