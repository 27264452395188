import React from 'react'
import css from './download.css'
import downloadIcon from './download.svg'

const Download = ({ someProp }) => (
    <div className="w-50 w-30-m w-20-l tr pr1 cta">
        <a className="download pa2 f7 tc br1 nowrap pr3 pl3" href="/download/">Get Frendli</a>
        {/*<a className="di dn-l" href="https://www.frendli.com/download/"><span className="dib download-mobile"/> </a>*/}
    </div>
)

export default Download
