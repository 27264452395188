import React from 'react'
import menuIcon from './menu.png'
import css from './menu.css'

const Menu = () => (
 	<a href="/" className="w-50 w-40-m w-20-l tl pl2 pl1-ns  dn" title="Frendli.com">
    	<div className="fl pl6 w-100 tl menu db"></div>
 	</a>
)

export default Menu
